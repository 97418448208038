import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Link } from "react-router-dom";
//import Button from 'react-bootstrap/Button';

const NavBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <div>

            <Navbar bg="light" expand="lg">
                
                    <Link className="navbar-brand" to="/">M3nus</Link>
                   
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggle} />
                <Navbar.Collapse isOpen={isOpen} id="basic-navbar-nav">
                  
                    <Form inline>
                        <FormControl type="text" placeholder="Find Restaurant" className="mr-sm-2" />
                      
                    </Form>
                    <Nav className="mr-auto">
                        <Nav.Link href="#">Share</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Navbar>

        </div>);
}

export default NavBar;