import React from 'react';
import './Card.css';
import { FirebaseDB, FirebaseStore } from '../Firebase/FirebaseApp';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class RestaurantSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurants: [],
    };
    this.logourl = this.logourl.bind(this)
  }

  componentDidMount() {
    FirebaseDB.collection("restaurant")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        });
        this.setState({ restaurants: data })
      });
  }

  logourl(logo, logoid) {
    const logourl = "logos/" + logo;
    FirebaseStore
      .ref()
      .child(logourl)
      .getDownloadURL()
      .then(function (url) {
        var img = document.getElementById(logoid);
        img.src = url;
      }).catch(function (error) {
        console.log(error)
      });
  }

  render() {
    const { restaurants } = this.state;
    return (
      <div >
        <Container fluid="sm">
          {restaurants.map(restaurant => (
            <Row key={restaurant.id}>
              <Col  >
                <Card className="shadow p-3 mb-2 bg-white rounded mr-1 ml-1 mt-2">
                  <Link to={`/${restaurant.id}`}>
                    <Card.Img id={restaurant.id} variant="top" src={this.logourl(restaurant.logo, restaurant.id)} />
                  </Link>
                  <Card.Body>
                    <Card.Title>{restaurant.name}</Card.Title>
                    <Card.Text>
                      {restaurant.address}
                    </Card.Text>
                  </Card.Body>
                </Card>

              </Col>
            </Row>
          ))}
        </Container>
      </div>
    );
  }
}

export default RestaurantSearch;