import React from 'react';
import './Card.css';
import { FirebaseDB } from '../Firebase/FirebaseApp';
import Plates from './Plates';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';

class Menus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      sections: [],
      menuid: "",
      restaurantname: "",
      restaurantid: ""
    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    let restaurantid = params.restaurantid;
    let menuid = params.menuid
    this.setState({
      restaurantid: restaurantid,
      menuid: menuid
    });
    let fbRest = FirebaseDB.collection("restaurant").doc(restaurantid)
    //get all menus  
       
    fbRest
      .collection("menu")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        this.setState({ menus: data })
      });
    //get sections
    fbRest.collection("menu").doc(menuid).collection("section")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        this.setState({ sections: data });
      });
  }
  render() {
    const { restaurantid } = this.state;
    //const { restaurantname } = this.state;
    const { menuid } = this.state;
    const { sections } = this.state;
    const { menus } = this.state;
    return (
      <div>
        <Container fluid>
          <Row className="align-items-center">
            <Col >
              <h4>{restaurantid}</h4>                    
            </Col>
            <Col >
            <Dropdown>
              <Dropdown.Toggle variant="success" size="lg" id="dropdown-basic">
                {menuid}
              </Dropdown.Toggle>

              <Dropdown.Menu>

                {menus.map(menu => (

                  <Link key={menu.id} className="dropdown-item mt-1 mb-1" to={`/${restaurantid}/${menu.id}`}>{menu.name}</Link>

                ))
                }
              </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          {sections.map(section => (
            <Row key={section.id} >
              <Col >
                <Accordion className="shadow-sm  mb-1 bg-white mt-1 noborder">
                  <Card className="noborder" >
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.toggleState}>
                      {section.name}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <Plates restaurantid={restaurantid} menuid={menuid} sectionid={section.id} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
          ))}
        </Container>
      </div>

    );
  }
}

export default Menus;