import React, { Component } from 'react';
import './App.css';
import { FirebaseAuth } from './Firebase/FirebaseApp';
import Menus from "./Card/Menus";
import Restaurant from "./Card/Restaurant";
import RestaurantSearch from "./Card/RestaurantSearch";
import NavBar from "./Card/NavBar";
import { FirebaseDB } from './Firebase/FirebaseApp';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
/*
todo:
handle route path errors and forward to 4040
*/

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      userid: "",
      loginerrorcode: "",
      loginerrormessage:""
    };
 
  
  }

  componentDidMount() {

    FirebaseAuth.signInAnonymously().catch(function (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      this.setStat({
        loginerrorcode: errorCode,
        loginerrormessage: errorMessage
      })
    });
    
    FirebaseAuth.onAuthStateChanged(function (user) {

      if (user) {
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        let currURl = window.location.href;
        let currNow = Date.now();
        let currNew = new Date()
        let currDate = Date()
        console.log("anonymous", isAnonymous, "-", uid)
        
        FirebaseDB
          .collection("user")
          .doc(uid)
          .collection("login")
          .doc(currNew.toString())
          .set({
            datenow: currNow,
            datenew: currNew,
            date: currDate,
            urllogin: currURl

          })
          .then(function () {
            console.log("added to db login by user:",uid);
          })
            .catch(function (error) {
              console.error("Error adding user to db: ", error);
            });
      } else {
        // User is signed out.
        // ...
        console.log("not signed in")
      }
      // ...
    });
  }
 
  render(){
    return (
      <div className="App">
   
        <Router>
          <NavBar />
          <Switch>
            <Route path="/:restaurantid" component={Restaurant} />
            <Route path="/:restaurantid/:menuid" render={(props) => (
              <Menus key={props.match.params.menuid} {...props} />)} />  
            <Route path="/" component={RestaurantSearch} />
          </Switch>
        </Router>
      </div>
    )
  }
}


export default App;
