import React from 'react';
import './Card.css';
import { FirebaseDB } from '../Firebase/FirebaseApp';
import Options from './Options';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
class Plates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plates: [],
            sectionid: "",
            menuid: "",
            restaurantid: ""
        };
        this.currency = this.currency.bind(this)
    }

    componentDidMount() {
        let restaurantid = this.props.restaurantid;
        let menuid = this.props.menuid;
        let sectionid = this.props.sectionid;
        this.setState({
            restaurantid: restaurantid,
            sectionid: sectionid,
            menuid: menuid
        });
        FirebaseDB.collection("restaurant").doc(restaurantid).collection("menu").doc(menuid).collection("section").doc(sectionid).collection("plate")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map((doc) => {
                    return { id: doc.id, ...doc.data() }
                })
                this.setState({ plates: data });
            });
    }

    currency(price) {
        if (price) {
            return "$" + price
        } else {
            return ""
        };
    };
    render() {
        const { restaurantid } = this.state;
        const { menuid } = this.state;
        const { sectionid } = this.state;
        const { plates } = this.state;

        return (
            <Container fluid>
                {plates.map(plate => (
                    <Row key={plate.id}  >
                        <Col >
                            <Accordion className="shadow-sm  mb-1 bg-white mt-1 noborder">
                                <Card className="noborder" >
                                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.toggleState}>
                                        {plate.name} {this.currency(plate.price)}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            {plate.description}
                                            <Options restaurantid={restaurantid} menuid={menuid} sectionid={sectionid} plateid={plate.id} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                ))}
            </Container>

        );
    }
}

export default Plates;