import React from 'react';
import './Card.css';
import { FirebaseDB } from '../Firebase/FirebaseApp';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class Items extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      optionid: "",
      plateid: "",
      sectionid: "",
      menuid: "",
      restaurantid: ""
    };
    this.currency = this.currency.bind(this)
  }

  componentDidMount() {
    let restaurantid = this.props.restaurantid;
    let menuid = this.props.menuid;
    let sectionid = this.props.sectionid;
    let plateid = this.props.plateid;
    let optionid = this.props.optionid;
    this.setState({
      restaurantid: restaurantid,
      sectionid: sectionid,
      menuid: menuid,
      plateid: plateid,
      optionid: optionid
    });
    FirebaseDB
      .collection("restaurant").doc(restaurantid)
      .collection("menu").doc(menuid)
      .collection("section").doc(sectionid)
      .collection("plate").doc(plateid)
      .collection("option").doc(optionid)
      .collection("item")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        this.setState({ items: data });
      });
  }
  //assign dollar value
  currency(price) {
    if (price) {
      return "$" + price
    } else {
      return ""
    };
  };
  render() {
    const { items } = this.state;
    return (
      <Container fluid="sm">

        {items.map(item => (
          <Row key={item.id}>
            <Col  >
              <Card className="shadow bg-white rounded mr-1 ml-1 mt-2">
                <Card.Body>
                  <Card.Text>{item.name} {this.currency(item.price)}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}

      </Container>

    );
  }
}

export default Items;