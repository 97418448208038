import React from 'react';
import './Card.css';
import { FirebaseDB } from '../Firebase/FirebaseApp';
import Items from './Items';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';

class Options extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            plateid: "",
            sectionid: "",
            menuid: "",
            restaurantid: ""
        };
    }

    componentDidMount() {
        let restaurantid = this.props.restaurantid;
        let menuid = this.props.menuid;
        let sectionid = this.props.sectionid;
        let plateid = this.props.plateid;
        this.setState({
            restaurantid: restaurantid,
            sectionid: sectionid,
            menuid: menuid,
            plateid: plateid
        });
        FirebaseDB
            .collection("restaurant").doc(restaurantid)
            .collection("menu").doc(menuid)
            .collection("section").doc(sectionid)
            .collection("plate").doc(plateid)
            .collection("option")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map((doc) => {
                    return { id: doc.id, ...doc.data() }
                })
                this.setState({ options: data });
            });
    }
    render() {
        const { restaurantid } = this.state;
        const { menuid } = this.state;
        const { sectionid } = this.state;
        const { plateid } = this.state;
        const { options } = this.state;
        return (
            <Container fluid>
                {options.map(option => (
                    <Row key={option.id}>
                        <Col >
                            <Accordion className="shadow-sm  mb-1 bg-white mt-1 noborder">
                                <Card className="noborder" >
                                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.toggleState}>
                                        {option.name} {option.action}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <Items restaurantid={restaurantid} menuid={menuid} sectionid={sectionid} plateid={plateid} optionid={option.id} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                ))}
            </Container>

        );
    }
}

export default Options;